<template>
  <div class="form">
    <div class="wrapper illustration illustration_dots">
      <Backlink title="information" service="refMortgage" backlink="/refinance-mortgage/type" />
      <div class="content">
        <div class="content__container">
          <form @submit.prevent="submitHandler">
            <p class="question">
              {{ "rm_s4p16_text_2" | localize }}
            </p>
            <div class="form__group">
              <v-text-field
                v-model="increase_money"
                class="input_number"
                inputmode="numeric"
                pattern="[0-9]*"
                @blur="$v.increase_money.$touch()"
                @change="checkForm($v.increase_money)"
                @keypress="onKeyPressHandler"
                @keyup="onKeyUpPriceHandler('increase_money', increase_money)"
                :class="{
                  invalid:
                    $v.increase_money.$dirty && !$v.increase_money.required,
                }"
                :hint="
                  ($v.increase_money.$dirty && !$v.increase_money.required
                    ? 'form_reqiered'
                    : 'empty') | localize
                "
                :placeholder="'rm_s4p16_placeholder_1' | localize"
                append-icon="icon-₪"
                ref="increase_money"
                outlined
              ></v-text-field>
            </div>

            <p class="question">{{ "rm_s4p16_text_3" | localize }}</p>
            <div class="form__group">
              <v-select
                v-model="increase_object"
                @blur="$v.increase_object.$touch(); checkForm($v.increase_object);"
                :items="getList('rm_s4p16_select_1_value_', 5)"
                :class="{
                  invalid:
                    $v.increase_object.$dirty && !$v.increase_object.required,
                }"
                item-text="name"
                item-value="value"
                :placeholder="'select' | localize"
                :menu-props="{ offsetY: true }"
                append-icon="icon-arrow-down"
                ref="increase_object"
                outlined
              ></v-select>
            </div>

            <div class="error__container">
              <p class="error" v-if="error">{{ error | localize }}</p>
            </div>

            <button
              type="button"
              class="button button__form"
              :class="{ button__form_disabled: !success }"
              @click="submitHandler"
            >
              {{ "form_continue" | localize }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VueCookies from 'vue-cookies'
import Backlink from '@/components/base/Backlink.vue'
import { formMixin } from '@/mixins/formMixin.js'
import { commonMixin } from '@/mixins/commonMixin.js'
import { numberMixin } from '@/mixins/numberMixin.js'

const refMortgage = VueCookies.get('refMortgage') || {}

export default {
  name: 'Refinance_mortgage_increase',
  components: { Backlink },
  mixins: [formMixin, commonMixin, numberMixin],
  data: () => ({
    objects: [],
    increase_money: refMortgage.increase_money || null,
    increase_object: refMortgage.increase_object || null,
    error: null
  }),
  validations: {
    increase_money: { required },
    increase_object: { required }
  },
  created () {
    this.increase_money = refMortgage.increase_money ? this.formatNumber(refMortgage.increase_money) : null
  },
  methods: {
    submitHandler () {
      this.increase_money = this.parseNumber(this.increase_money)

      if (this.$v.$invalid) {
        this.$v.$touch()
        this.success = false
        return
      }

      refMortgage.increase_money = this.increase_money
      refMortgage.increase_object = this.increase_object
      this.$cookies.set('refMortgage', refMortgage)

      this.$store.commit('loading', true)
      const userId = this.$store.state.user ? this.$store.state.user.id : 0
      this.axios.get('user_service', {
        params: {
          user_id: userId,
          service_id: this.$store.state.services.refMortgage
        }
      }).then((response) => {
        const userServiceId = response.data.id || 0
        const amount = parseInt(response.data.amount, 10) + parseInt(this.increase_money, 10)
        this.axios.put('user_service/' + userServiceId, {
          user_id: userId,
          service_id: this.$store.state.services.refMortgage,
          status: this.$store.state.service_status,
          amount: amount
        }).then(() => {
          this.$store.commit('loading', false)
          this.dropSteps('refMortgage', this.$router.currentRoute.path, '/refinance-mortgage/decrease')
          this.addMissedSteps('refMortgage', ['/refinance-mortgage/decrease'])
          this.$router.push('/refinance-mortgage/something-else')
        }).catch((error) => {
          this.$store.commit('loading', false)
          this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
          this.$scrollTo('.error__container', 300, { offset: -400 })
        })
      }).catch((error) => {
        this.error = error.response.data && error.response.data.error && typeof error.response.data.error === 'string' ? error.response.data.error : 'server_error'
        this.$store.commit('loading', false)
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
    }
  }
}
</script>
